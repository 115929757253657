<template>
  <div>
    <!-- Your template content here -->
    <div>
      <Header />
      <div class="main">
        <!-- GIF 动画 -->
        <transition
          name="fade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <router-view />
        </transition>
      </div>
      <Footer />
    </div>
    <!-- loading 过度页面 -->
    <div class="loader" :class="loader ? 'transition' : 'hide'">
      <div class="loader-main" v-show="loader">
        <div class="logo widthLogo">
          <el-image
            :src="LogoW"
            :class="loader ? 'image widthLogo' : 'image'"
          ></el-image>
        </div>
        <div :class="loader ? 'white widthChange' : 'white'"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import LogoW from "@/assets/logo-w.png";
import Header from "./header/index.vue";
import Footer from "./footer/index.vue";
import bus from "@/utils/bus";
bus.on("loader", (value) => {
  loader.value = value;
  setTimeout(() => {
    loader.value = false;
  }, 1500);
});
setTimeout(() => {
  loader.value = false;
}, 1500);
const loader = ref(true);
// 定义参数
const props = defineProps({
  problemList: Array,
  fileList: Array,
  // 如果需要的话，可以添加其他参数的定义
});

// 定义要发射的事件
const emits = defineEmits(["deleteFn", "addFn"]);
</script>

<style scoped lang="scss">
.main {
  min-height: calc(100vh - 300px);
}
.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: #1a1a1a;
  z-index: 99999999999;
}
.hide {
  transition: 1s;
  width: 0;
}
.loader-main {
  position: absolute;
  width: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .logo {
    width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.white {
  margin-top: 16px;
  width: 100px; /* 初始宽度为0 */
  height: 0px; /* 高度设置为200px */
  background-color: white; /* 背景颜色设置为白色 */
  position: relative;
  display: flex;
  justify-content: center;
}
.box {
  width: 50px;
  height: 50px;
  background-color: red;
  overflow: hidden;
  position: relative;
}

.white.widthChange::before {
  content: "";
  position: absolute;
  // right: 0;
  width: 100%;
  height: 10px;
  background-color: #fff;
  animation: expandWidth 1.5s forwards;
}

@keyframes expandWidth {
  0% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
.widthChange {
  // animation: widthChange 1.5s ease-out; /* 应用动画，持续时间为1秒，动画完成后保持最后一帧状态 */
}
@keyframes widthChange {
  0% {
    width: 150px;
    left: 50%;
  }
  100% {
    left: 0;
    width: 0;
  }
}
/* Your scoped CSS here */
.image {
  width: 100px;
  opacity: 0;
  transition: 1s;
}
.widthLogo {
  animation: widthLogo 1.5s ease-out;
}
@keyframes widthLogo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
